<template>
    <div class="admin-content">
		<h3>게시판 권한 관리</h3>
		<div class="flex-wrap right">
			<div class="ml10">
				<div class="btn-wrap">
                    <button type="button" 
                    class="btn-box green"
                    @click="eventHandler.onSaveButtonClick">저장</button>
				</div>
			</div>
		</div>
    
        <div class="flex-wrap half">
			<div class="col-3">

                <!-- 총 개수 -->
		        <div class="total-box">
		        	<p>Total <strong>{{state.dataTable.group.groups === null ? 0 : state.dataTable.group.groups.length}}</strong></p>
		        </div>

                <DataTable class="p-datatable-sm"
                    scrollHeight="497px"
                    :value="state.dataTable.group.groups"
                    @row-click="eventHandler.dataTable.group.onRowClick"
                    :resizableColumns="true"
                    :rowHover="true">
            
                    <template #header>
                        그룹 선택
                    </template>
                
                    <template #empty>
                        데이터가 없습니다.
                    </template>
                
                    <Column field="CodeVal" header="그룹ID" :sortable="true" style="text-align:center; width:150px;" headerClass="tc"></Column>
                    <Column field="CodeName" header="그룹명" :sortable="true" style="text-align:center; width:150px;" headerClass="tc"></Column>
                
                </DataTable>
            </div>

            <div class="col-9">

                <!-- 총 개수 -->
		        <div class="total-box">
		        	<p>Total <strong>{{state.dataTable.group.groupAuthority === null ? 0 : state.dataTable.group.groupAuthority.length}}</strong></p>
		        </div>

                <DataTable class="p-datatable-sm"
                    scrollHeight="497px"
                    columnResizeMode="fit"
                    :value="state.dataTable.group.groupAuthority"
                    :resizableColumns="true"
                    :rowHover="true">
            
                    <template #header>
                        권한관리
                    </template>
                
                    <template #empty>
                        데이터가 없습니다.
                    </template>
                
                    <Column field="BoardName" header="구분" :sortable="true" style="text-align:center; width:120px;" headerClass="tc"></Column>
                
                    <Column field="AccessYn" header="접근" :sortable="true" style="text-align:center; width:120px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.isAccess" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                
                    <Column field="WriteYn" header="글작성" :sortable="true" style="text-align:center; width:120px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.isWrite" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                
                    <Column field="ReplyYn" header="답글작성" :sortable="true" style="text-align:center; width:120px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.isReply" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                
                    <Column field="CommentYn" header="댓글작성" :sortable="true" style="text-align:center; width:120px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.isComment" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                
                    <Column field="TopFixYn" header="상단글고정" :sortable="true" style="text-align:center; width:120px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.isTopFix" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                
                    <Column field="FileDownloadYn" header="파일다운로드" :sortable="true" style="text-align:center; width:120px;" headerClass="tc">
                        <template #body="{data}">
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.isFileDownload" :binary="true"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { boardManage } from '@/modules/site-training/board-manage.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager.js';
export default {
    components: { },
    setup() {
        
        // 스토어
        const store = useStore();

        // state
        const state = reactive({

            // 데이터 테이블 관련
            dataTable: {
                
                // 그룹
                group: {

                    // 그룹목록
                    groups: new Array(),

                    // 게시판권한 목록
                    groupAuthority: new Array(),
                },

                selectedGroupcode:'',
                
            },

        });

        // 메서드
        const methods = {

            // 그룹목록 가져오기
            getGroups: () => {

                // 스토어 값 변경
                store.commit('setLoadingStatus', true);

                boardManage.getGroups().then(groups => {

                    state.dataTable.group.groups = groups;

                    }).finally(() => store.commit('setLoadingStatus', false));

            },

            // 그룹 권한 가져오기
            getJSAuthorityList: (groupCode) => {

                // 스토어 값 변경
                store.commit('setLoadingStatus', true);

                boardManage.getJSAuthorityList(groupCode).then(groups => {

                    state.dataTable.group.groupAuthority = groups;

                    }).finally(() => store.commit('setLoadingStatus', false));

            },

            // 임시계정 저장
            saveAuthorityBoardGroupInfo: () => {

                const groupCode = state.dataTable.selectedGroupcode;
                const userID = store.getters.getUserId;

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                boardManage.saveAuthorityBoardGroupInfo(groupCode, userID, state.dataTable.group.groupAuthority)
                
                .then((response) => {

                    if(response.data.success) {
        
                        alertManager.toast.save();

                        // 목록 재조회
                        methods.getGroups();

                    }

                })
                    
                .finally(() =>{

                    state.dataTable.group.groupAuthority.forEach((item) => {

                        methods.addAuthorityBoardHistory("U", item.BoardCategory); 
                        
                    })

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);
 
                });
                
            },

             //그룹 권한 로그 저장
             addAuthorityBoardHistory: (type, boardCategory) => {

                let logInfo = new Object();

                if (type == 'C') { 
                    logInfo.logType = 'C000011';
                }

                else if (type == 'R') {
                    logInfo.logType = 'C000012';
                }

                else if (type == 'U')
                    logInfo.logType = 'C000013';
                
                else if (type == 'D') {
                    logInfo.logType = 'C000014';   
                }

                logInfo.boardCategory = boardCategory;
                logInfo.groupCode = state.dataTable.selectedGroupcode;
                logInfo.device = !store.getters.isMobile ? 'C000018' : 'C000019';
                logInfo.userNo = store.getters.getUserId;
                

                // 스토어 값 변경
                store.commit("setLoadingStatus", true);
                
                boardManage.addAuthorityBoardHistory(logInfo)
                    
                .then((response) => {

                    if(response.data.success) {

                        console.log("로그 저장되었습니다. data : %o", logInfo);

                    }

                })
                    
                .finally(() =>{

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", false);

                });
                
            },
        }

        // 이벤트 핸들러
        const eventHandler = {

            onSaveButtonClick: () => {
                if(!state.dataTable.selectedGroupcode) return;
                alertManager.confirm.save().then(() => {

                    //체크박스 선택한값 전달
                    state.dataTable.group.groupAuthority.forEach(row => {

                        row.AccessYn = row.isAccess ? 1 : 0;
                        row.CommentYn = row.isComment ? 1 : 0;
                        row.FileDownloadYn = row.isFileDownload ? 1 : 0;
                        row.ReplyYn = row.isReply ? 1 : 0;
                        row.TopFixYn = row.isTopFix ? 1 : 0;
                        row.WriteYn = row.isWrite ? 1 : 0;

                    });

                    methods.saveAuthorityBoardGroupInfo();

                });
            },
        
            // 데이터 테이블 관련
            dataTable: {

                // 그룹
                group: {

                    // 행 클릭 이벤트 처리
                    onRowClick: event => {

                        //선택한 그룹 그룹코드 바인딩
                        state.dataTable.selectedGroupcode=event.data.CodeVal;

                        // 게시판 권한 관리 목록 조회
                        methods.getJSAuthorityList(state.dataTable.selectedGroupcode);

                    },
                },
            },
        }

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 그룹관리 초기조회
            methods.getGroups();

        });

        return {
            state,
            methods,
            eventHandler,
        }
    }
}
</script>