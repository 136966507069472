import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 *  게시판 권한 관리 관련 기능 클래스
 */
class BoardManage {

    /**
     * 생성자
     */
    constructor() { }

    /**
     * 게시판 그룹목록 가져오기
     * @returns 
     */
    getGroups() {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz';
            request.methodId = 'SelectGroupList';
            request.parameters.ParentCode = 'ROOT';
            request.parameters.UseYn = 'Y';

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));

        });
    }

    /**
     * 게시판 권한 가져오기
     * @param {*} groupCode 
     * @returns 
     */
     getJSAuthorityList(groupCode) {

        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz';
            request.methodId = 'SelectAuthorityBoardGroupList';
            request.parameters.GroupCode = groupCode;

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                rows.forEach(row => {
                    
                    row.isAccess = row.AccessYn === 1 ? true : false;
                    row.isComment = row.CommentYn === 1 ? true : false;
                    row.isFileDownload = row.FileDownloadYn === 1 ? true : false;
                    row.isReply = row.ReplyYn === 1 ? true : false;
                    row.isTopFix = row.TopFixYn === 1 ? true : false;
                    row.isWrite = row.WriteYn === 1 ? true : false;
                });

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
            
        });
    }
    
    /**
     * 게시판 권한 저장
     * @param {string} groupCode
     * @param {string} userID 
     * @param {Array} groupAuthority 
     * @returns 
     */
     saveAuthorityBoardGroupInfo(groupCode, userID, groupAuthority) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.AuthorityGroupBiz';
            request.methodId = 'InsertAuthorityBoardGroupSave';           
            request.parameters.GroupCode = groupCode;
            request.parameters.UserID = userID;
            request.parameters.AuthorityGrid = JSON.stringify(groupAuthority);

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));

        });
    }

    /**
     * 게시판 권한 로그 저장
     * @param {Object} logInfo 
     * @returns 
     */
     addAuthorityBoardHistory(logInfo) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Training.Biz.CommonBiz';
            request.methodId = 'AddAuthorityBoardHistory';

            request.parameters = logInfo;

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));

        });
    }
}

export const boardManage = new BoardManage();